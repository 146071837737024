<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-18 15:18:30
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-02-21 16:47:36
-->
<template>
  <div class="container-warp">
    <el-tabs v-model="tabsValue">
      <el-tab-pane
        :key="index"
        v-for="(item, index) in tabsList"
        :label="item.title"
        :name="item.name"
      >
        <div class="study-item" @click="_goDetail">
          <div class="study-item-left">
            <div class="img-box">
              <img src="@/assets/img/img.jpg" alt=""/>
            </div>
            <div class="info-box">
              <div class="info-box-t">
                【鉴定知识】贵金属鉴定学习
                <span>入职培训</span>
              </div>
              <div class="info-box-m">
                计划描述计划描述计划描述计划描述计划描述计划描述计划描述
              </div>
              <div class="info-box-b">
                <div class="info-box-b-l">
                  <img src="@/assets/img/icon_Duration.png" alt="" />总时长
                  <span style="color: #0089FF;">200</span>min
                </div>
                <div class="line-box"></div>
                <div class="info-box-b-m">
                  <img src="@/assets/img/icon_Course.png" alt="" /><span style="color: #0089FF;"
                    >3</span
                  >门课程
                </div>
                <div class="line-box"></div>
                <div class="info-box-b-r">
                  <img
                    src="@/assets/img/icon_Finaldate.png"
                    alt=""
                  />结束时间 2023-01-10
                </div>
              </div>
            </div>
          </div>
          <div class="study-item-right">
            <el-button type="primary" plain>继续学习</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabsValue: '1',
      tabsList: [
        {
          title: '进行中',
          name: '1'
        },
        {
          title: '已完成',
          name: '2'
        }
      ]
    }
  },
  methods: {
    // 去详情
    _goDetail () {
      this.$router.push({
        path: '/myStudyPlanDetail'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-warp {
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  min-height: 800px;
}
.study-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  margin-bottom: 16px;
  background: linear-gradient(to bottom, #f0f8ff, #ffffff);
  border: 1px solid #e7f4ff;
  border-radius: 8px;
  .study-item-left {
    display: flex;
    .img-box {
      margin-right: 5px;
      width: 120px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .info-box {
      .info-box-t {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        span {
          display: inline-block;
          padding: 3px 12px;
          margin-left: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          border-radius: 8px 0px 8px 0px;
          background: linear-gradient(to right, #31adf6, #5096ff);
        }
      }
      .info-box-m {
        margin: 8px 0 16px 7px;
        font-size: 14px;
        color: #666666;
      }
      .info-box-b {
        display: flex;
        align-items: center;
        margin-left: 6px;
        font-size: 12px;
        color: #666666;
        .info-box-b-l,
        .info-box-b-m,
        .info-box-b-r {
          img {
            margin-right: 4px;
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }
        .line-box {
          margin: 0 12px;
          width: 1px;
          height: 16px;
          background: #dcdcdc;
        }
      }
    }
  }
}
</style>
